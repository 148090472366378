<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "containers",
              "add-resource-to-container",
              "add-resource-to-container"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="submitSearch"
        @removeSelectedFilter="removeSelectedFilter"
        :hasFilters="true"
        :selectedFilters="selectedFilters"
        :routeName="'r_edit-resource-add-resources-to-container-search-filters'"
      ></search>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul class="clebex-item-section pill" v-if="resources && resources.length">
      <li
        class="clebex-item-section-item"
        v-for="resource in resources"
        :key="resource.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedResource(resource)"
        >
          <span
            class="label"
            v-if="resource.all_parents && resource.all_parents.length"
          >
            <span class="text">
              <span class="highlight">{{ `${resource.name}, ` }}</span>
              {{ displayParentNames(resource.all_parents) }}
            </span>
          </span>
          <span class="label" v-else>
            {{ resource.name }}
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="
                selectedResources &&
                  selectedResources.filter(item => item.id === resource.id)
                    .length
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
            <span class="follow-up-icon-item" v-else> </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "ContainersAddResource",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      search: null
    };
  },
  created() {
    // this.getResourcesList({ includes: ["all_parents"] });
    this.getResources({ model_ids: null, reset: false });
    if (!this.search && this.$route.name === "r_resource-filters") {
      this.$router.push({ name: "r_resources" });
    }
  },
  watch: {
    searchActiveNum() {
      this.submitSearch();
    }
  },
  computed: {
    ...mapState("containers", [
      "listOfResources",
      "selectedResources",
      "resourcesInContainer"
    ]),
    ...mapState("resource", [
      "resources",
      "resource",
      "resourceTypes",
      "resourceModels",
      "resourceAttributes",
      "resource",
      "resourceTranslations",
      "resourcesPaginationData",
      "resourcesLoading",
      "resourceFunctionTypes"
    ]),
    ...mapState("level", ["levels"]),
    ...mapState("searchFiltersResources", [
      "selectedTypeIds",
      "selectedFunctionTypeIds",
      "selectedLevelIds",
      "selectedModelIds",
      "selectedAttributesIds",
      "selectedCapacity",
      "searchActiveNum"
    ]),
    ...mapState("language", ["languages"]),
    selectedFilters() {
      let filters = [];
      const {
        resourceTypes,
        selectedTypeIds,
        resourceModels,
        selectedModelIds,
        resourceAttributes,
        selectedAttributesIds,
        selectedCapacity,
        resourceFunctionTypes,
        selectedFunctionTypeIds,
        levels,
        selectedLevelIds
      } = this;
      if (
        levels &&
        levels.data &&
        levels.data.length &&
        selectedLevelIds &&
        selectedLevelIds.length
      ) {
        const filtered = levels.data.filter(
          item => selectedLevelIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "level" }];
        });
      }

      if (
        resourceFunctionTypes &&
        resourceFunctionTypes.data &&
        resourceFunctionTypes.data.length &&
        selectedFunctionTypeIds &&
        selectedFunctionTypeIds.length
      ) {
        const filtered = resourceFunctionTypes.data.filter(
          item => selectedFunctionTypeIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceFunctionType" }];
        });
      }

      if (
        resourceTypes &&
        resourceTypes.data &&
        resourceTypes.data.length &&
        selectedTypeIds &&
        selectedTypeIds.length
      ) {
        const filtered = resourceTypes.data.filter(
          item => selectedTypeIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceType" }];
        });
      }

      if (
        resourceModels &&
        resourceModels.data &&
        resourceModels.data.length &&
        selectedModelIds &&
        selectedModelIds.length
      ) {
        const filtered = resourceModels.data.filter(
          item => selectedModelIds.indexOf(item.id) !== -1
        );
        filters = [...filters, ...filtered];
      }

      if (
        resourceAttributes &&
        resourceAttributes.data &&
        resourceAttributes.data.length &&
        selectedAttributesIds &&
        selectedAttributesIds.length
      ) {
        const filtered = resourceAttributes.data.filter(
          item => selectedAttributesIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceAttribute" }];
        });
      }

      if (selectedCapacity) {
        filters.push({
          id: "cap1",
          name:
            this.displayLabelName("resources", "resources", "capacity") +
            " " +
            selectedCapacity,
          type: "capacity"
        });
      }
      return filters;
    },
    searchQuery: {
      get() {
        return this.$store.state.search.searchQuery;
      },
      set(value) {
        this.$store.commit("search/setSearchQuery", value, {
          root: true
        });
      }
    }
  },
  methods: {
    ...mapActions("containers", ["getResourcesList", "setSelectedResource"]),
    ...mapActions("resource", [
      "getResources",
      "getResource",
      "duplicateResourceApi",
      "deleteResource",
      "checkDeleteResource"
    ]),
    ...mapActions("level", ["resetLevelsState"]),
    ...mapActions("searchFiltersResources", [
      "setSelectedTypeIds",
      "setSelectedFunctionTypeIds",
      "setSelectedLevelIds",
      "setSelectedModelIds",
      "setSelectedAttributeIds",
      "setSelectedCapacity"
    ]),
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    },
    toggleSearch() {
      this.search = !this.search;
      if (
        !this.search &&
        this.$route.name ===
          "r_edit-resource-add-resources-to-container-search-filters"
      ) {
        this.$router.push({
          name: "r_edit-resource-add-resources-to-container"
        });
      }
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.getResources({ model_ids: null, reset: true });
    },
    submitSearch() {
      this.getResources({ model_ids: null, reset: true });
    },
    removeSelectedFilter(filter) {
      if (filter.model) {
        return this.setSelectedModelIds(filter.id);
      }

      if (filter.type === "resourceAttribute") {
        return this.setSelectedAttributeIds(filter.id);
      }

      if (filter.type === "resourceType") {
        return this.setSelectedTypeIds(filter.id);
      }

      if (filter.type === "resourceFunctionType") {
        return this.setSelectedFunctionTypeIds(filter.id);
      }

      if (filter.type === "level") {
        return this.setSelectedLevelIds(filter.id);
      }

      if (filter.type === "capacity") {
        return this.setSelectedCapacity(0);
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
